<template>
  <b-list-group>
    <b-list-group-item
        class="d-flex align-items-center"
        button
        :disabled="!isInit"
        @click="onClick"
    >
      <img
          class="mr-3"
          src="../../assets/google-icon.svg"
          alt="Google Icon"
          style="height: 50px; width: 50px"
      />
      <span>Se connecter avec Google</span>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: 'GoogleAuthButton',
  data() {
    return {
      isInit: false,
    };
  },
  methods: {
    onClick() {
      this.$gAuth
          .signIn()
          .then((GoogleUser) => {
            if (!GoogleUser.getAuthResponse().id_token) {
              throw "Google id_token not set";
            }

            this.$emit("login", GoogleUser.getAuthResponse().id_token);
          })
          .catch((err) => {
            this.$swal.fire({
              icon: "error",
              title: "Une erreur est survenue lors de la connexion à Google",
            });
            // eslint-disable-next-line no-console
            console.error(err);
          });
    },
  },
  created() {
    const checkGAuthInitStatus = setInterval(() => {
      if (this.$gAuth.isInit) {
        this.isInit = true;
        clearInterval(checkGAuthInitStatus);
      }
    });
  },
};
</script>
