<template>
  <b-row>
    <GoogleAuthButton @login="login" class="mx-auto"/>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";
import GAuth from "vue-google-oauth2";
import GoogleAuthButton from "../components/login/GoogleAuthButton";

export default {
  name: "JalisConnect",
  components: {GoogleAuthButton},
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
  created() {
    Vue.use(GAuth, {
      clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
      scope: "email",
      prompt: "consent",
      fetch_basic_profile: false,
    });
  },

  methods: {
    async login(userGoogleJwt) {
      try {
        await this.$store.dispatch("auth/login", userGoogleJwt)
      } catch (err) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la connexion",
          text: err.message,
        });
        return
      }
      window.location.replace(`${this.$route.query.redirect_uri}&stal_token=${this.$store.state.auth.userToken}`);
    },
  }
}
</script>

